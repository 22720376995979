<template>
  <div class="box login">
    <img class="logo" :src="loginLogo" alt="" srcset="">
    <div class="box-card">
      <img class="left" src="login.png" alt="" srcset="">
      <div class="right">
        <div class="title">{{title}}客服调度平台</div>
        <div class="formbox">
          <div class="clearfix">系统登录</div>
          <div>
            <el-form :model="loginData" :rules="rules" ref="loginData" label-width="14%" class="form" size="medium">
              <el-form-item label="" prop="mobile">
                <el-input v-model="loginData.mobile" autocomplete @keyup.enter.native="login('loginData')" placeholder="请输入用户名">
                  <i slot="prepend" class="iconfont icon-yonghu-tianchong"></i>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input type="password" v-model="loginData.password" show-password @keyup.enter.native="login('loginData')" placeholder="请输入密码">
                  <i slot="prepend" class="iconfont icon-mima"></i>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="captcha">
                <div class="captcha">
                  <el-input v-model="loginData.captcha" class="input" @keyup.enter.native="login('loginData')" placeholder="请输入验证码">
                    <i slot="prepend" class="iconfont icon-securityCode-b"></i>
                  </el-input>
                  <el-image :src="captchaImg" class="img" @click.native="loadCatcha" title="点击刷新"></el-image>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button class="login-botton" type="primary" @click="login('loginData')">登录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  title: "登录",
  data() {
    return {
      title: process.env.VUE_APP_PROJECT_NAME,
      loginLogo: process.env.VUE_APP_PC_LOGIN_LOGO,
      loginData: {
        captcha: "",
        client: "",
        mobile: "",
        password: "",
      },
      captchaImg: "",
      rules: {
        mobile: [
          { required: true, message: "请输入系统登录用户名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
        ],
        captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.loadCatcha();
  },
  methods: {
    //加载验证码
    loadCatcha() {
      this.$http.get(this.$api.sysUserCaptcha, null, (res) => {
        this.captchaImg = res.base64Img;
        this.loginData.client = res.client;
      });
    },
    // 登录
    login(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.$http.post(this.$api.sysUserLogin, this.loginData, (res) => {
            let { aliCall, token, userInfo } = res;
            localStorage.Authorization = token;
            localStorage.accessKeyId = aliCall.aliUser;
            localStorage.accessKeySecret = aliCall.aliPassword;
            this.$http.get(this.$api.checkActivationCode, null, (codes) => {
              if (codes.days < 3) {
                let year = new Date().getFullYear();
                let month = new Date().getMonth() + 1;
                let date = new Date().getDate();
                this.$alert(
                  `<h2 style="text-align: center;line-height: 2em;">系统服务到期提醒</h2><div style="font-size: 18px;color: #333;"><p style="text-indent: 2em;line-height: 2.2em;">温馨提示，贵司使用的系统服务授权将于<span style="color: red;fontw-weight:500;">${codes.days}天后到期</span>，请及时联系我司项目经理进行授权延期。授权到期后将无法登录系统，因此造成的损失将与我司无关！</p><p style="text-align: right;line-height: 2em;">武汉城市方略科技有限公司</p><p style="text-align: right;line-height: 2em;">${year}年${month}月${date}日</p></div>`,
                  "",

                  {
                    type: "",
                    customClass: "dielineBox",
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: "我已知晓",
                    callback: (action) => {
                      this.$store.commit("userInfo/setUserInfo", userInfo);
                      this.$router.replace("/branch");
                    },
                  }
                );
              } else {
                this.$store.commit("userInfo/setUserInfo", userInfo);
                this.$router.replace("/branch");
              }
            });
          });
        } else {
          this.$message.warning("请将登录账号信息填写完整！");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  position: relative;
  background-color: #357cff;
  .logo {
    width: auto;
    height: 55px;
    position: absolute;
    z-index: 9;
    top: 55px;
    left: 9%;
  }
  .box-card {
    position: absolute;
    top: 130px;
    right: 0;
    bottom: 0;
    left: -230px;
    margin: auto;
    width: 1224px;
    height: 700px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 700px;
      height: auto;
    }
    .title {
      text-align: center;
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 60px;
      margin-bottom: 35px;
    }
    .formbox {
      width: 463px;
      height: 460px;
      background: #ffffff;
      box-shadow: 5px 9px 15px 0px rgba(62, 64, 66, 0.07);
      border-radius: 8px;
    }
    .clearfix {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #010101;
      line-height: 82px;
      text-align: center;
      border-bottom: 2px dotted #bfbfbf;
      margin-bottom: 36px;
    }
    /deep/.el-input--medium .el-input__inner {
      height: 45px;
      line-height: 45px;
    }
    /deep/ .el-button--primary {
      height: 45px;
      background: linear-gradient(0deg, rgba(255, 170, 4, 0.71), #ff9220);
      border: 0;
    }
  }
}

.form {
  padding-right: 14%;
}
.captcha {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .input {
    flex-basis: 1;
    width: auto;
  }
  .img {
    width: 107px;
    height: 45px;
    margin-left: 10px;
    border: 1px solid #ececec;
    cursor: pointer;
  }
}
.login-botton {
  width: 100%;
  margin-top: 20px;
}
</style>